// extracted by mini-css-extract-plugin
export var form = "form-module--form--kRRqr";
export var formLabel = "form-module--formLabel--wXKJk";
export var formInputPlaceholder = "form-module--formInputPlaceholder--AKvJZ";
export var formInput = "form-module--formInput--0BdVu";
export var formInputPlaceholderError = "form-module--formInputPlaceholderError--v9KXg";
export var formInputPlaceholderActive = "form-module--formInputPlaceholderActive--1iZ8w";
export var formInputError = "form-module--formInputError--kSKqs";
export var formButton = "form-module--formButton--QcH9F";
export var errorMsg = "form-module--errorMsg--nA2Ub";
export var formSuccess = "form-module--formSuccess--bQS7G";
export var formSuccess__image = "form-module--formSuccess__image--LFMeI";
export var formSuccess__text = "form-module--formSuccess__text--jvfxn";
export var formPP = "form-module--formPP--j0LHR";