import React from 'react';
import cn from 'classnames';

import Button from '../button/button';

import * as s from './requestSuccess.module.scss';

const RequestSuccess = ({ closeModal }) => {
  return (
  <div className={cn(s.modalScreenSubmit)}>
    <img src="/images/success.svg" className={s.modalScreenSubmitImg} alt="" />
    <h4 className={s.modalTitle}>Заявка отправлена!</h4>
    <p className={s.modalText}>
      В ближайшее время мы<br/> свяжемся с вами
    </p>
    <Button large className={s.formButton} text={'Закрыть'} onClick={closeModal} />
  </div>
  );
};

export default RequestSuccess;
