import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import Modal from '../modal/modal';
import Form from '../form/form';

import * as s from './requestModal.module.scss';

const RequestModal = props => {
  const { setIsOpen, tariff, formName } = props;

  const closeModal = () => {
    setIsOpen(false);
  };

  const [maxWidth, setMaxWidth] = useState(480);

  const setModalContentMaxWidth = value => {
    setMaxWidth(value);
  };

  const [isFormSent, setIsFormSent] = useState(false);

  return (
    <Modal {...props} contentMaxWidth={maxWidth}>
      {!isFormSent && <h3 className={s.modalTitle}>Оставьте свои контакты</h3>}
      <Form
        tariff={tariff}
        btnName={'Оставить заявку'}
        useFor={'tariffs'}
        closeModal={closeModal}
        setModalContentMaxWidth={setModalContentMaxWidth}
        setIsFormSent={setIsFormSent}
        formName={formName}
      />
    </Modal>
  );
};

export default RequestModal;
