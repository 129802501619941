import React from 'react';
import cn from 'classnames';

import * as s from './modal.module.scss';

const Modal = ({
    children,
    isOpen,
    setIsOpen,
    className,
    closeModalCallback,
    contentMaxWidth
  }) => {
  const modalClasses = cn(
    s.modal,
    isOpen ? s.modalOpen : '',
    className
  );

  const closeModal = () => {
    setIsOpen(false);
    if (closeModalCallback) {
      closeModalCallback();
    }
  };

  const styles = contentMaxWidth ? {
    maxWidth: `${contentMaxWidth}px`
  } : {};

  return (
    <div className={modalClasses}>
      <div className={s.modalOverlay} onClick={closeModal} />
      <div className={s.modalMain} style={styles}>
        <button type="button" className={s.modalClose} onClick={closeModal} />
        <div className='modal-main-content'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
