export function toCamelCase (value) {
  return value.replace(/([_][a-z])/ig, letter => letter.toUpperCase().replace('_', ''));
}

export function convertToCamelCase (value) {
  if (!value) {
    return value;
  }

  const nextValue = {
    ...value
  };

  Object.entries(nextValue)
    .forEach(([key, value]) => {
      if (typeof value === 'object') {
        if (!Array.isArray(value)) {
          value = convertToCamelCase(value);
        }
      }

      const nextKey = toCamelCase(key);

      nextValue[nextKey] = value;

      if (nextKey !== key) {
        delete nextValue[key];
      }
    });

  return nextValue;
}
