import React, { useState } from 'react';
import cn from 'classnames';

import { globalHistory } from '@reach/router';

import API from '../../api';

import Button from '../button/button';
import RequestSuccess from '../request-success/requestSuccess';

import { isBot } from '../../helper/isBot';
import { yaEvent } from '../../helper/metrika';
import { usePhoneField } from '../../helper/hooks';
import { getUtmQueriesFromSearch } from '../../helper/utmQueries';
import { convertToCamelCase } from '../../helper/case';

import * as s from './form.module.scss';

const Form = props => {
  const {
    tariff,
    btnName,
    formName,
    useFor,
    closeModal,
    setModalContentMaxWidth,
    setIsFormSent
  } = props;

  const utmQueries = getUtmQueriesFromSearch(globalHistory.location.search);

  const { normalizePhone } = usePhoneField();

  const [success, setSuccess] = useState(false);
  const [fullName, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [status, setStatus] = useState('no');
  const [errorFullNameMsg, setFullNameErrMsg] = useState('');
  const [errorEmailMsg, setEmailErrMsg] = useState('');
  const [errorPhoneMsg, setPhoneErrMsg] = useState('');
  const [focusName, setNameFocus] = useState(false);
  const [focusEmail, setEmailFocus] = useState(false);
  const [focusPhone, setPhoneFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const placeHolderNameClass = cn(
    s.formInputPlaceholder,
    (focusName || fullName) && s.formInputPlaceholderActive,
    (status === 'error' || status === 'errorFullName') && s.formInputPlaceholderError
  );
  const placeHolderEmailClass = cn(
      s.formInputPlaceholder,
      (focusEmail || email) && s.formInputPlaceholderActive,
      (status === 'error' || status === 'errorEmail') && s.formInputPlaceholderError
  );
  const placeHolderPhoneClass = cn(
    s.formInputPlaceholder,
    (focusPhone || phone) && s.formInputPlaceholderActive,
    (status === 'error' || status === 'errorPhone') && s.formInputPlaceholderError
  );

  const inputClassName = cn(
    s.formInput,
    (status === 'error' || status === 'errorFullName') && s.formInputError
  );
  const inputClassEmail = cn(
      s.formInput,
      (status === 'error' || status === 'errorEmail') && s.formInputError
  );
  const inputClassPhone = cn(
    s.formInput,
    (status === 'error' || status === 'errorPhone') && s.formInputError
  );

  const changeNameHandler = e => {
    setName(e.target.value);
    if (e.target.value && fullName && status !== 'no') {
      setStatus('no');
      setFullNameErrMsg('');
    }
  };

  const changeEmailHandler = e => {
    setEmail(e.target.value);
    if (e.target.value && phone && status !== 'no') {
      setStatus('no');
      setEmailErrMsg('');
    }
  };

  const changePhoneHandler = e => {
    setPhone(normalizePhone(e.target.value, phone));
    if (e.target.value && email && status !== 'no') {
      setStatus('no');
      setPhoneErrMsg('');
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (navigator && isBot(navigator)) {
      return;
    }

    setStatus('no');
    let validation = 'no';

    if (!fullName?.length) {
      setFullNameErrMsg('Заполните поле');
      validation = 'fullName';
    }

    if (!email?.length) {
      setEmailErrMsg('Заполните поле');
      validation = 'errorEmail';
    }

    if (!phone?.length) {
      setPhoneErrMsg('Заполните поле');
      validation = validation ==='errorEmail' ? 'error' : 'errorPhone';
    }

    const emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (email?.length && !emailPattern.test(email)) {
      setEmailErrMsg('Неверный формат почты');
      validation = validation === 'error' || validation === 'errorPhone' ? 'error' : 'errorEmail';
    }

    if (phone?.length && phone?.length < 18) {
      setPhoneErrMsg('Неверный формат телефона');
      validation = validation === 'error' || validation === 'errorEmail' ? 'error' : 'errorPhone';
    }

    setStatus(validation);
    if (validation.includes('error')) {
      return;
    }

    setIsLoading(true);

    const apiPath = process.env.GATSBY_API_V2 + 'landing/leads';
    const response = await fetch(apiPath, {
      method: 'POST',
      mode: 'cors',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({
        fullName,
        email,
        phone,
        tariff,
        formName,
        utm: convertToCamelCase(utmQueries)
      })
    });
    switch (response.status) {
      case 200:
      case 201: {
        if (useFor === 'tariffs') {
          setModalContentMaxWidth(345);
          setIsFormSent(true);
        }

        setSuccess(true);
        setStatus('success');
        setNameFocus(false);
        setEmailFocus(false);
        setPhoneFocus(false);
        setEmail('');
        yaEvent('pushed_request_demo_button');
        break;
      }
      case 400:
      case 422: {
        const res = await response.json();
        let newStatus = 'no';
        if (res.violations) {
          res.violations.forEach(e => {
            if (e?.propertyPath === 'fullName') {
              setFullNameErrMsg(e.message || 'Неверный формат');
              newStatus = newStatus === 'errorFullName' || newStatus === 'error' ? 'error' : 'errorFullName';
              return;
            }
            if (e?.propertyPath === 'phone') {
              setPhoneErrMsg(e.message || 'Неверный формат телефона');
              newStatus = newStatus === 'errorEmail' || newStatus === 'error' ? 'error' : 'errorPhone';
              return;
            }
            if (e?.propertyPath === 'email') {
              setEmailErrMsg(e.message || 'Неверный формат email');
              newStatus = newStatus === 'errorPhone' || newStatus === 'error' ? 'error' : 'errorEmail';
            }
          });
        }
        setStatus(newStatus);
        break;
      }
      default: {
        const defaultErrorCaption = 'Сервис не отвечает. Попробуйте позже.';
        setFullNameErrMsg(defaultErrorCaption);
        setEmailErrMsg(defaultErrorCaption);
        setPhoneErrMsg(defaultErrorCaption);
        setStatus('error');
        yaEvent('pushed_request_demo_button');
        break;
      }
    }

    setIsLoading(false);
  };

  const onDemoBtnClick = () => {
    try {
      API.metric.dispatchEvent({
        event_id: 'LANDING_CLICK_DEMO-ORDER'
      });
    } catch (e) {}
  };

  return (
    success ?
      (useFor === 'tariffs' ?
      <RequestSuccess closeModal={closeModal} /> :
      <div className={s.formSuccess}>
        <img src={'/images/success.svg'} alt={''} className={s.formSuccess__image}/>
        <div className={s.formSuccess__text}>
          Мы получили вашу заявку на демо<br />
          и скоро с вами свяжемся
        </div>
      </div>)
    :
      <form
        onSubmit={handleSubmit}
        className={cn(s.form, 'request-form')}
      >
        <label className={s.formLabel}>
          <span className={placeHolderNameClass}>
            ФИО
          </span>
          <input
            type={'text'}
            name={'fullName'}
            value={fullName}
            autoComplete={'off'}
            onFocus={() => setNameFocus(true)}
            onBlur={() => setNameFocus(false)}
            onChange={changeNameHandler}
            className={inputClassName}
            disabled={isLoading}
          />
          {
            (status === 'error' || status === 'errorFullName') ? (
              <span className={s.errorMsg}>
                { errorFullNameMsg }
              </span>
            ) : null
          }
        </label>
        <label className={s.formLabel}>
          <span className={placeHolderEmailClass}>
            Электронная почта { formName === 'auth-rabotaru' ? 'в СберПодборе' : '' }
          </span>
          <input
            type={'text'}
            name={'email'}
            value={email}
            autoComplete={'off'}
            onFocus={() => setEmailFocus(true)}
            onBlur={() => setEmailFocus(false)}
            onChange={changeEmailHandler}
            className={inputClassEmail}
            disabled={isLoading}
          />
          {
            (status === 'error' || status === 'errorEmail') ? (
              <span className={s.errorMsg}>
                { errorEmailMsg }
              </span>
            ) : null
          }
        </label>
        <label className={s.formLabel}>
          <span className={placeHolderPhoneClass}>
            Телефон
          </span>
          <input
            type={'text'}
            name={'phone'}
            value={phone}
            autoComplete={'off'}
            onFocus={() => setPhoneFocus(true)}
            onBlur={() => setPhoneFocus(false)}
            onChange={changePhoneHandler}
            className={inputClassPhone}
            disabled={isLoading}
          />
          {
            (status === 'error' || status === 'errorPhone') ? (
              <span className={s.errorMsg}>
                { errorPhoneMsg }
              </span>
            ) : null
          }
        </label>
        {
          useFor !== 'tariffs' && <div className={cn(s.formPP, 'request-form__pp')}>
            Нажимая «Заказать демо», вы соглашаетесь с условиями <a href="/doc/Политика_обработки_персональных_данных.pdf" target="_blank" className="text-blue">Политики обработки персональных данных</a>
          </div>
        }
        <Button
          onClick={onDemoBtnClick}
          type={'submit'}
          medium
          className={cn(s.formButton, 'request-form__btn')}
          text={btnName || 'Заказать демо'}
          disabled={isLoading}
        />
        {
          useFor === 'tariffs' && <div className={cn(s.formPP, 'request-form__pp')}>
            Нажимая «Оставить заявку», вы соглашаетесь с условиями <a href="/doc/Политика_обработки_персональных_данных.pdf" target="_blank" className="text-blue">Политики обработки персональных данных</a>
          </div>
        }
      </form>
  );
};

export default Form;
